<template>
    <div class="main-container">

    </div>
</template>

<script>
export default {
    name : 'LegalNotices'
}
</script>

<style lang="scss" scoped>

</style>